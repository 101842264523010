<script>
import Vue from "vue";

import { format } from "date-fns";
import RaiDatePicker from "@/components/util/RaiDatePicker";

const EMPTY_SEARCH = {
  firstName: undefined,
  lastName: undefined,
  phoneNumber: undefined,
  searchAllStores: false,
  startDate: undefined,
  endDate: undefined,
  drsCustomerCode: undefined,
  drsTransId: undefined,
  newCustomersDate: undefined,
  flaggedCustomers: undefined,
  showOnlySellers: undefined,
  startTransactionDate: undefined,
  endTransactionDate: undefined,
  minTransactionAmount: undefined,
};

export default {
  format,
  name: "CustomersAdvancedSearch",
  components: {
    RaiDatePicker,
  },
  props: {
    parsedFilter: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      newCustomers: false,
    };
  },
  computed: {
    search() {
      if (this.parsedFilter && this.parsedFilter.isAdvanced) {
        return this.parsedFilter.data;
      }

      return this.buildEmptySearch();
    },
    isSmallScreen() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    flaggedChecked: {
      get() {
        // TODO - use Vue.set
        return this.search.flaggedCustomers === true;
      },
      set(newFlaggedValue) {
        // Ensures that flaggedCheck and notFlaggedCheck can NOT both be checked, but both CAN be unchecked.
        // Also ensures that the right flag for filtering is set.
        if (newFlaggedValue) {
          this.search.flaggedCustomers = true;
        } else {
          if (this.notFlaggedChecked) {
            this.search.flaggedCustomers = false;
          } else {
            this.search.flaggedCustomers = undefined;
          }
        }
      },
    },
    notFlaggedChecked: {
      get() {
        return this.search.flaggedCustomers === false;
      },
      set(newNotFlaggedValue) {
        // Ensures that flaggedCheck and notFlaggedCheck can NOT both be checked, but both CAN be unchecked.
        // Also ensures that the right flag for filtering is set.
        if (newNotFlaggedValue) {
          this.search.flaggedCustomers = false;
        } else {
          if (this.flaggedChecked) {
            this.search.flaggedCustomers = true;
          } else {
            this.search.flaggedCustomers = undefined;
          }
        }
      },
    },
    shoppersChecked: {
      get() {
        return (
          this.search.showOnlySellers === false ||
          this.search.showOnlySellers === undefined ||
          this.search.showOnlySellers === null
        );
      },
      set(newShoppersValue) {
        if (newShoppersValue) {
          if (this.sellersChecked) {
            this.search.showOnlySellers = undefined;
          } else {
            this.search.showOnlySellers = false; // But this should not happen
          }
        } else {
          if (this.sellersChecked) {
            this.search.showOnlySellers = true;
          } else {
            this.search.showOnlySellers = undefined; // But this should not happen
          }
        }
      },
    },
    sellersChecked: {
      get() {
        return (
          this.search.showOnlySellers === true ||
          this.search.showOnlySellers === undefined ||
          this.search.showOnlySellers === null
        );
      },
      async set(newSellerValue) {
        if (newSellerValue) {
          if (this.shoppersChecked) {
            this.search.showOnlySellers = undefined;
          } else {
            this.search.showOnlySellers = true;
          }
        } else {
          if (this.shoppersChecked) {
            this.search.showOnlySellers = false;
          } else {
            this.search.showOnlySellers = undefined;
          }
        }
      },
    },
  },

  watch: {
    parsedFilter(newFilter) {
      this.setNewCustomers();
    },
    newCustomers(checkedValue) {
      if (checkedValue && !this.search.newCustomersDate) {
        Vue.set(
          this.search,
          "newCustomersDate",
          this.getDefaultCustomersDate()
        );
      }
    },
  },
  mounted() {
    this.setNewCustomers();
  },
  methods: {
    setNewCustomers() {
      this.newCustomers =
        this.parsedFilter.isAdvanced &&
        !!this.parsedFilter.data.newCustomersDate;
    },
    getDefaultCustomersDate() {
      let defaultNewCustomersDate = new Date();
      defaultNewCustomersDate.setDate(defaultNewCustomersDate.getDate() - 30);
      return this.$options.format(defaultNewCustomersDate, `yyyy-MM-dd`);
    },
    buildEmptySearch() {
      return Object.assign({}, EMPTY_SEARCH, {
        newCustomersDate: this.getDefaultCustomersDate(),
      });
    },
    update() {
      let searchParsed = Object.assign({}, this.search);

      // Clear new customers date if we don't search new ones
      if (!this.newCustomers) {
        searchParsed.newCustomersDate = undefined;
      }

      this.$emit("update", searchParsed);
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<template>
  <v-form ref="advancedSearchForm" class="d-flex flex-column">
    <div>
      <div class="text-overline">Customer</div>
      <v-divider class="flex-shrink-0"></v-divider>
    </div>
    <div class="d-sm-flex">
      <div class="mr-sm-2">
        <!-- TODO - have a common style for forms!! -->
        <!-- TODO - include to have some margins so that it works for different type of inputs -->
        <v-text-field
          v-model="search.firstName"
          :label="'First name'"
          autocomplete="no"
          hide-details
          @keyup.enter.exact="
            () => {
              update();
            }
          "
        />
      </div>
      <div class="mr-sm-2">
        <v-text-field
          v-model="search.lastName"
          class="ml-sm-2"
          :label="'Last name'"
          autocomplete="no"
          hide-details
          @keyup.enter.exact="update"
        />
      </div>
      <div class="mr-sm-2">
        <v-text-field
          v-model="search.phoneNumber"
          class="ml-sm-2"
          :label="'Phone'"
          autocomplete="no"
          mask="phone"
          type="tel"
          hide-details
          @keyup.enter.exact="update"
        />
      </div>
    </div>

    <div class="d-sm-flex mt-2">
      <div class="align-self-center mt-4 pt-1 text-body-1">Last visit date</div>
      <div class="ml-sm-2">
        <RaiDatePicker
          v-model="search.startDate"
          hide-details
          append-icon="$vuetify.icons.calendar"
          label="Start"
          :max="$options.format(new Date(), `yyyy-MM-dd`)"
        />
      </div>
      <div class="ml-sm-3">
        <RaiDatePicker
          v-model="search.endDate"
          hide-details
          append-icon="$vuetify.icons.calendar"
          label="End"
          :max="$options.format(new Date(), `yyyy-MM-dd`)"
        />
      </div>
    </div>

    <div class="d-sm-flex mt-2">
      <div class="mr-sm-2">
        <v-text-field
          v-model="search.drsCustomerCode"
          :label="'DRS Customer code'"
          autocomplete="no"
          hide-details
          @keyup.enter.exact="update"
        />
      </div>
    </div>

    <div class="d-sm-flex mt-2">
      <div class="align-self-center mt-4 pt-1 text-body-1">Flagged</div>
      <div class="ml-sm-2 d-flex">
        <!-- This is not nicely bound to v-model, but instead it is: -->
        <!--   - readonly, bound to input-value and changed on click event -->
        <!--   - if not done with readonly and change on click - we have problems when programatically changing this -->
        <v-checkbox
          hide-details
          readonly
          :input-value="notFlaggedChecked"
          label="Not flagged"
          @click="notFlaggedChecked = !notFlaggedChecked"
        />
        <!-- This is not nicely bound to v-model, but instead it is: -->
        <!--   - readonly, bound to input-value and changed on click event -->
        <!--   - if not done with readonly and change on click - we have problems when programatically changing this -->
        <v-checkbox
          class="ml-2"
          hide-details
          readonly
          :input-value="flaggedChecked"
          label="Flagged"
          @click="flaggedChecked = !flaggedChecked"
        />
      </div>

      <div class="align-self-center ml-sm-8 mt-4 pt-1 text-body-1">
        Shoppers/Sellers
      </div>
      <div class="ml-sm-2 d-flex">
        <!-- This is not nicely bound to v-model, but instead it is: -->
        <!--   - readonly, bound to input-value and changed on click event -->
        <!--   - if not done with readonly and change on click - we have problems when programatically changing this -->
        <v-checkbox
          hide-details
          readonly
          :input-value="shoppersChecked"
          label="Shopper"
          @click="shoppersChecked = !shoppersChecked"
        />
        <!-- This is not nicely bound to v-model, but instead it is: -->
        <!--   - readonly, bound to input-value and changed on click event -->
        <!--   - if not done with readonly and change on click - we have problems when programatically changing this -->
        <v-checkbox
          class="ml-2"
          hide-details
          readonly
          :input-value="sellersChecked"
          label="Seller"
          @click="sellersChecked = !sellersChecked"
        />
      </div>
    </div>

    <div class="d-sm-flex mt-2">
      <div
        :class="{
          'ml-sm-2': true,
          'new-customers-date-checkbox-desktop': !isSmallScreen,
        }"
      >
        <v-checkbox
          v-model="newCustomers"
          hide-details
          label="Show only new customers?"
        />
      </div>
      <div v-if="newCustomers" class="d-sm-flex">
        <div class="align-self-center ml-sm-6 mt-4 pt-1 text-body-1">
          Show customers from
        </div>
        <div class="ml-sm-2" cols="4">
          <RaiDatePicker
            v-model="search.newCustomersDate"
            hide-details
            append-icon="$vuetify.icons.calendar"
            label="Start"
            :max="$options.format(new Date(), `yyyy-MM-dd`)"
          />
        </div>
      </div>
    </div>

    <div class="mt-6">
      <div class="text-overline">Transaction</div>
      <v-divider class="flex-shrink-0"></v-divider>
    </div>
    <div class="d-sm-flex mt-2">
      <div class="mr-sm-2">
        <v-text-field
          v-model="search.drsTransId"
          :label="'Transaction number'"
          autocomplete="no"
          hide-details
          @keyup.enter.exact="update"
        />
      </div>
      <div class="mr-sm-2">
        <v-text-field
          v-model="search.minTransactionAmount"
          :label="'Min transaction amount'"
          autocomplete="no"
          hide-details
          @keyup.enter.exact="update"
        />
      </div>
    </div>

    <div class="d-sm-flex mt-2">
      <div class="align-self-center mt-4 pt-1 text-body-1">
        Transaction date
      </div>
      <div class="ml-sm-2">
        <RaiDatePicker
          v-model="search.startTransactionDate"
          hide-details
          append-icon="$vuetify.icons.calendar"
          label="Start"
          :max="$options.format(new Date(), `yyyy-MM-dd`)"
        />
      </div>
      <div class="ml-sm-3">
        <RaiDatePicker
          v-model="search.endTransactionDate"
          hide-details
          append-icon="$vuetify.icons.calendar"
          label="End"
          :max="$options.format(new Date(), `yyyy-MM-dd`)"
        />
      </div>
    </div>

    <div class="mt-6">
      <div class="text-overline">Stores</div>
      <v-divider class="flex-shrink-0"></v-divider>
    </div>
    <div class="d-sm-flex mt-2">
      <div class="ml-sm-2">
        <v-checkbox
          v-model="search.searchAllStores"
          hide-details
          :true-value="false"
          :false-value="true"
          label="Limit to this store?"
        />
      </div>
    </div>

    <div class="d-sm-flex mt-4">
      <v-spacer />
      <v-btn class="elevation-0" text @click="cancel">Cancel</v-btn>
      <v-btn class="elevation-0" color="accent" text @click="update"
        >Search</v-btn
      >
    </div>
  </v-form>
</template>

<style scope lang="scss">
.new-customers-date-checkbox-desktop {
  min-height: 48px;
}
</style>

<script>
import VxAppBar from "@/components/vx/VxAppBar";
import VxAppBarSearch from "@/components/vx/VxAppBarSearch";
import AdvancedSearch from "@/components/customers/AdvancedSearch";

export default {
  name: "CustomersAppBar",
  components: {
    VxAppBar,
    VxAppBarSearch,
    AdvancedSearch,
  },
  props: {
    filter: {
      type: String,
      default: undefined,
    },
    parsedFilter: {
      type: Object,
      default: undefined,
    },
  },
};
</script>

<template>
  <VxAppBar title="Customers" mobile-search :filter="filter">
    <template #search="{ back }">
      <VxAppBarSearch
        title="Customers"
        advanced
        :min-chars="3"
        :filter="filter"
        :parsed-filter="parsedFilter"
        @back="back"
      >
        <template #advanced="{ update, cancel }">
          <AdvancedSearch
            :parsed-filter="parsedFilter"
            @update="update($event)"
            @cancel="cancel"
          />
        </template>
      </VxAppBarSearch>
    </template>
  </VxAppBar>
</template>
